<template>
  <section class="intro">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      content-class="intro-modal"
      hide-overlay
      persistent
      :fullscreen="isMobile"
    >
    <v-card :color="isMobile ? 'white' : 'rgba(255,255,255,0.95)'" class="intro-card d-flex flex-column align-center">
      <img src="@/assets/images/onv_logo.png" class="onv-logo px-4 mb-2" alt="">
      <section class="film-intro mb-6">
        <video class="video" ref='video' :src='video_src' controls></video>
      </section>
      <v-btn class="intro-btn pa-4" max-width="200px" color="primary" @click="$router.push('/menu')">Passer l'intro</v-btn>
    </v-card>
    </v-dialog>
    <div class="logos-bg ">
        <div class="logo-container py-1">
          <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="">
        </div>
        <div class="logo-container">
          <img src="@/assets/images/logo_action_logement.png" alt="">
        </div>
      </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: true,
    video_src: "https://afpols-onv-bo-bucket.s3.eu-west-3.amazonaws.com/assets/Onv_Copro_Intro-2_max_compress.m4v",
    loading: false,
    isMuted: true
  }),
  computed: {
    ...mapGetters(['isMobile'])
  },
}
</script>

<style lang="scss">


</style>
