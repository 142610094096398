<template>
  <section class="email-validation d-flex justify-center align-center">
    <div class="message d-flex justify-center align-center">
      <p v-if="info.message" :class="`${info.color}--text`">
        {{ info.message }}
      </p>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </section>
</template>

<script>
export default {
  async created () {
    try {
      const registerRes = await this.axios.post(
        `${process.env.VUE_APP_API}auth/register/${this.$route.params.code}`
      )
      this.info.message =
        'Votre compte a bien été validé vous allez être redirigé'
      this.info.color = 'green'
      setTimeout(() => {
        this.$router.push('/auth')
      }, 3000)
    } catch (error) {
      if (
        error.response &&
        error.response.data.message === 'Invalid verification code'
      ) {
        this.info.message = 'Le lien de vérification est invalide'
        this.info.color = 'red'
      } else if (error.response) {
        this.info.message = error.response.data.message
        this.info.color = 'red'
      }
    }
  },
  data: () => ({
    info: { message: '', color: '' }
  })
}
</script>

<style lang="scss">
.email-validation {
  width: 100%;
  height: 100%;
  &:before {
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    width: 117vw;
    height: 100vh;
    background-image: url('~@/assets/images/home_bg_desktop.jpg');
    background-size: cover;
    background-position: center center;
  }
  .message {
    z-index: 2;
    width: 70%;
    border-radius: 20px;
    overflow: hidden;
    height: 200px;
    background: rgba(255, 255, 255, 0.95);
  }
}
</style>
