<template>
  <section class="password-reset">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      content-class="intro-modal"
      hide-overlay
      persistent
      :fullscreen="isMobile"
    >
    <v-card :color="isMobile ? 'white' : 'rgba(255,255,255,0.95)'" class="pa-4 d-flex justify-center align-center" height="40vh">
      <p v-if="info.message" :class="`${info.color}--text caption`">{{info.message}}</p>
      <v-form v-model="isValid" @submit.prevent="validatePassword" class="form d-flex flex-column justify-center align-center">
        <p class="caption">Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule et un chiffre</p>
        <v-text-field
          outlined
          :type='show ? "text" : "password"'
          class="input"
          placeholder="Nouveau mot de passe"
          :rules="[].concat(passwordRules)"
          v-model='password'
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          full-width
        ></v-text-field>
        <v-btn class="intro-btn pa-4" max-width="200px" color="primary" type="submit">Valider</v-btn>
      </v-form>
    </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: true,
    show: false,
    password: "",
    passwordRules: [
      v => !!v || 'Veuillez entrer un mot de passe',
      v => /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/.test(v) || "Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule et un chiffre",
    ],
    isValid: false,
    info: {
      message: "",
      color: ""
    }
  }),
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    async validatePassword () {
      if(this.isValid) {
        try {
          const resetRes = await this.axios.post(`${process.env.VUE_APP_API}auth/reset/${this.$route.params.code}`, {
            password: this.password
          })
          this.info.message = 'Votre mot de passe a bien été modifié vous allez être redirigé'
          this.info.color = 'green'
          setTimeout(() => {
            this.$router.push('/auth')
          }, 3000);
        } catch (error) {
           if (error.response && error.response.data.message === "Invalid verification code") {
              this.info.message = 'Le lien de vérification est invalide'
              this.info.color = 'red'
            }      
        }

      }
    }
  },
}
</script>

<style lang="scss">
  .password-reset {
    width: 100%;
      height: 100%;
      &:before {
          position: fixed;
          content:'';
          top: 0;
          left: 0;
          width: 117vw;
          height: 100vh;
          background-image: url('~@/assets/images/home_bg_desktop.jpg');
          background-size: cover;
          background-position: center center;
      }
  }
      .form {
        width: 60%;
      }
</style>
